import './App.css';
import Picker from './components/Picker';

function App() {
	return (
		<div className='App'>
			<Picker />
		</div>
	);
}

export default App;
